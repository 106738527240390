import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CreativeTimHeader from 'components/creative-tim/Header/Header.js'
import HeaderLinks from './headerLinks'
import { PaletteType, Slide, useScrollTrigger } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  offset: theme.mixins.toolbar,
}))

export interface HeaderProps {
  siteTitle?: string
  theme: PaletteType
  onToggleTheme: () => void
}

const Header: FC<HeaderProps> = ({ siteTitle = '', onToggleTheme, theme }) => {
  const classes = useStyles()
  const trigger = useScrollTrigger({ threshold: 10 })

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      <div>
        <CreativeTimHeader
          color="transparent"
          brand={siteTitle}
          rightLinks={<HeaderLinks onToggleTheme={onToggleTheme} />}
          fixed
          changeColorOnScroll={{
            height: 10,
            color: theme === 'dark' ? 'dark' : 'white',
          }}
        />
        <div
          className={classes.offset}
          style={{ display: trigger ? 'none' : undefined }}
        />
      </div>
    </Slide>
  )
}

export default Header
