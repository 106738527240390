import React, { FC } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import {
  Container,
  Typography,
  Theme,
  Link,
  Breadcrumbs,
} from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
    backgroundColor: theme.palette.background.paper,
  },
}))

const Footer: FC = () => {
  const classes = useStyles()
  return (
    <footer className={classes.footer}>
      <Container maxWidth="md">
        <Typography component="div" variant="body1" color="textSecondary">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              href="http://www.evo-is.com"
              target="_blank"
              rel="noopener"
              color="inherit"
            >
              © {new Date().getFullYear()}, Built by
              {` `}Evo-IS Limited
            </Link>
            <Link href="/privacy" color="inherit">
              Privacy Policy
            </Link>
            <Link href="/terms" color="inherit">
              Terms and Conditions
            </Link>
          </Breadcrumbs>
        </Typography>
      </Container>
    </footer>
  )
}

export default Footer
