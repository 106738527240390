import React, { FC, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Container,
  Theme,
  PaletteType,
  Link,
  Button,
  ThemeProvider,
  CssBaseline,
} from '@material-ui/core'
import 'fontsource-roboto/latin-ext.css'
import CookieConsent, { Cookies } from 'react-cookie-consent'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Footer from './footer'
import useSiteMetadata from 'hooks/useSiteMetadata'
import useLocalStorage from 'hooks/useLocalStorage'
import useLogoData from 'hooks/useSiteLogo'
import Header from './header'
import { useSnackbar } from 'notistack'
import themes from 'theme'
import { clearFetchSessionState } from 'libs/sessionStorageUtility'
import { Helmet } from 'react-helmet-async'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
}))

const Layout: FC<{ container?: boolean; location?: Location }> = ({
  children,
  container = false,
}) => {
  const isServer = typeof window === 'undefined'
  const classes = useStyles()
  const { title, siteUrl } = useSiteMetadata()
  const { logo } = useLogoData()
  const [theme, setTheme] = useLocalStorage('theme', 'light' as PaletteType)
  const { enqueueSnackbar } = useSnackbar()
  const [marketingEnabled, setMarketingEnabled] = useState<boolean>(
    !!Cookies.get('CookieConsent')
  )

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light')
  }

  !isServer &&
    window.addEventListener('sworkerUpdate', () => {
      enqueueSnackbar(
        'This application has been updated. Reload to display the latest version',
        {
          variant: 'info',
          persist: true,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          action: function RefreshButton() {
            /* istanbul ignore next */
            return (
              <Button
                aria-label="Refresh page"
                onClick={() => {
                  clearFetchSessionState()
                  window.location.reload()
                }}
                color="inherit"
              >
                Refresh
              </Button>
            )
          },
        }
      )
    })

  return (
    <ThemeProvider theme={themes[theme as PaletteType]}>
      <CssBaseline />
      <div className={classes.root}>
        {marketingEnabled && (
          <Helmet>
            <script
              data-ad-client="ca-pub-3355627533449841"
              async
              src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
            ></script>
          </Helmet>
        )}
        <GatsbySeo
          openGraph={{
            images: [
              {
                url: `${siteUrl}${logo.childImageSharp.fluid.srcWebp}`,
                alt: 'Logo',
              },
            ],
            url: isServer ? undefined : `${siteUrl}${location?.pathname}`,
          }}
        />
        <Header siteTitle={title} onToggleTheme={toggleTheme} theme={theme} />
        {container ? (
          <Container component="main" maxWidth="md" className={classes.main}>
            {children as JSX.Element}
          </Container>
        ) : (
          <main>{children}</main>
        )}
        <Footer />
      </div>
      <CookieConsent
        overlay
        enableDeclineButton
        onAccept={() => {
          Cookies.set('gdpr-analytics-enabled', true, { expires: 365 })
          Cookies.set('gdpr-marketing-enabled', true, { expires: 365 })
          setMarketingEnabled(true)
        }}
        onDecline={() => {
          Cookies.remove('gdpr-analytics-enabled')
          Cookies.remove('gdpr-marketing-enabled')
          setMarketingEnabled(true)
        }}
      >
        <p>
          This website stores cookies on your computer. These cookies are used
          to collect information about how you interact with this website and
          allow us to remember you. We use this information in order to improve
          and customize your browsing experience and for analytics and metrics
          about our visitors on this website.
        </p>

        <p>
          If you decline, your information won’t be tracked when you visit this
          website. A single cookie will be used in your browser to remember your
          preference not to be tracked.{' '}
          <Link href="/privacy" color="secondary">
            Privacy Policy
          </Link>
        </p>
      </CookieConsent>
    </ThemeProvider>
  )
}

export default Layout
