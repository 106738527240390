import React, { FC } from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import styles from 'assets/jss/material-kit-react/components/headerLinksStyle'
import { Button, useTheme } from '@material-ui/core'
import Brightness4Icon from '@material-ui/icons/Brightness4'
import WbSunnyIcon from '@material-ui/icons/WbSunny'
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt'

const useStyles = makeStyles(styles)

export interface HeaderLinkProps {
  onToggleTheme: () => void
}

const HeaderLinks: FC<HeaderLinkProps> = ({ onToggleTheme }) => {
  const classes = useStyles()
  const theme = useTheme()
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Button data-testid="toggle-button" onClick={onToggleTheme}>
          {theme.palette.type === 'dark' ? (
            <WbSunnyIcon />
          ) : (
            <Brightness4Icon />
          )}{' '}
          Toggle Theme
        </Button>
        <Button component={GatsbyLink} to="/blog/">
          <ViewQuiltIcon /> Blog
        </Button>
      </ListItem>
    </List>
  )
}

export default HeaderLinks
